var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.loading
        ? _c("loading")
        : _c(
            "v-card",
            { staticClass: "centered2", attrs: { "max-width": "800px" } },
            [
              _c("v-card-title", { staticClass: "primary" }, [
                _c("span", { staticClass: "white--text headline" }, [
                  _vm._v("Reports")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.$store.getters.isSubscribed === false
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "centered mt-2",
                                      attrs: {
                                        value:
                                          _vm.$store.getters.isSubscribed ===
                                          false,
                                        color: "warning"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { color: "accent" }
                                        },
                                        [_vm._v("warning")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "white--text" },
                                        [
                                          _vm._v(
                                            "Emails will not be sent, because your account is not subscribed."
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { color: "accent" }
                                        },
                                        [_vm._v("warning")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { color: "accent", block: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$router.push(
                                                "account?subscribe=true"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Subscribe")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _vm._v(
                              "\n            Report recipients will be emailed a daily or weekly link enabling them to view screenshots.  They will also be notified of tamper or uninstall attempts, deleted screenshots, and monitoring changes.  Weekly reports are sent on Sunday shortly after midnight UTC time.  Daily reports are sent at midnight UTC time.\n          "
                            )
                          ]),
                          _vm._l(_vm.emails, function(emailObj) {
                            return [
                              _c(
                                "v-col",
                                {
                                  key:
                                    emailObj.email + "." + emailObj.frequency,
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { outlined: "" } },
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "text-lowercase" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(emailObj.email) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-card-subtitle",
                                        { staticClass: "text-capitalize pb-1" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                emailObj.frequency + " reports"
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _vm.featureFlags.immediateAlerts
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-1 pb-1",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                        lg: "4"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            "Immediate Alerts: " +
                                                              (emailObj.alertImmediately
                                                                ? "Enabled"
                                                                : "Disabled")
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.featureFlags.phoneNumber
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-1 pb-1",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                        lg: "4"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            "Phone Number: " +
                                                              (emailObj.phoneNumber
                                                                ? emailObj.phoneNumber
                                                                : "none")
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pt-1 pb-1",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                    lg: "4"
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Limited to:")
                                                  ]),
                                                  emailObj.identities &&
                                                  emailObj.identities.length > 0
                                                    ? _vm._l(
                                                        emailObj.identities,
                                                        function(device) {
                                                          return _c(
                                                            "span",
                                                            { key: device.id },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    device.name +
                                                                      ", "
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-capitalize"
                                                        },
                                                        [_vm._v(" all devices")]
                                                      )
                                                ],
                                                2
                                              ),
                                              emailObj.canView
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-1 pb-1",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Access to: "
                                                      ),
                                                      _c("br"),
                                                      _c(
                                                        "div",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "font-weight-bold"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Screenshots: "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                "" +
                                                                  (emailObj
                                                                    .canView
                                                                    .screenshots
                                                                    ? "Yes"
                                                                    : "No")
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "font-weight-bold"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Screenshot Meta Data: "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                "" +
                                                                  (emailObj
                                                                    .canView
                                                                    .screenshotMetaData
                                                                    ? "Yes"
                                                                    : "No")
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "font-weight-bold"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Websites: "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                "" +
                                                                  (emailObj
                                                                    .canView
                                                                    .websites
                                                                    ? "Yes"
                                                                    : "No")
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "font-weight-bold"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Screen & App Time: "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                "" +
                                                                  (emailObj
                                                                    .canView
                                                                    .screenAppTime
                                                                    ? "Yes"
                                                                    : "No")
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "font-weight-bold"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Event Timeline: "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                "" +
                                                                  (emailObj
                                                                    .canView
                                                                    .eventTimeline
                                                                    ? "Yes"
                                                                    : "No")
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "font-weight-bold"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "High Risk Only Data:"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                "" +
                                                                  (emailObj
                                                                    .canView
                                                                    .highRiskOnly
                                                                    ? "Yes"
                                                                    : "No")
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                outlined: !_vm.$store.state
                                                  .isMobile,
                                                icon: _vm.$store.state.isMobile,
                                                color: "error",
                                                disabled:
                                                  _vm.$store.getters
                                                    .isAccountLocked
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteEmail(
                                                    emailObj
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("delete")]),
                                              !_vm.$store.state.isMobile
                                                ? _c("span", [_vm._v("Delete")])
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }),
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _vm.$store.getters.isAccountLocked
                              ? _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "font-style": "italic",
                                      padding: "0 1em 0.5em 1em"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              You cannot delete report recipients because your account is locked.  To unlock, go to the "
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "account" } },
                                      [_vm._v("account")]
                                    ),
                                    _vm._v(" page.\n            ")
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]),
                          !_vm.addingNewRecipient
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _vm.$store.getters.isAccountLocked
                                    ? [
                                        _c(
                                          "div",
                                          [
                                            _vm._v(
                                              "Your account is locked, so you cannot add anyone to receive reports.  If you wish to unlock your account you can do so from the "
                                            ),
                                            _c(
                                              "router-link",
                                              { attrs: { to: "account" } },
                                              [_vm._v("accounts page")]
                                            ),
                                            _vm._v(".")
                                          ],
                                          1
                                        )
                                      ]
                                    : [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "white--text mt-2",
                                            staticStyle: {
                                              display: "block",
                                              "margin-left": "auto",
                                              "margin-right": "auto"
                                            },
                                            attrs: {
                                              color: "primary",
                                              disabled: _vm.emails.length > 4
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.addingNewRecipient = true
                                              }
                                            }
                                          },
                                          [_vm._v("+ Add report recipient")]
                                        ),
                                        _vm.emails.length > 4
                                          ? _c("div", [
                                              _vm._v(
                                                "You can only send reports to 5 email addresses."
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                ],
                                2
                              )
                            : _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { outlined: "" } },
                                    [
                                      _c("v-card-title", [
                                        _vm._v(
                                          "\n                Add new recipient\n              "
                                        )
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", md: "6" }
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "mr-3" },
                                                    [_vm._v("Send reports:")]
                                                  ),
                                                  _vm._v(
                                                    " \n                    "
                                                  ),
                                                  _c(
                                                    "v-btn-toggle",
                                                    {
                                                      attrs: {
                                                        mandatory: "",
                                                        color: "primary"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.newRecipientFrequency,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.newRecipientFrequency = $$v
                                                        },
                                                        expression:
                                                          "newRecipientFrequency"
                                                      }
                                                    },
                                                    [
                                                      _c("v-btn", [
                                                        _vm._v(
                                                          "\n                        daily\n                      "
                                                        )
                                                      ]),
                                                      _c("v-btn", [
                                                        _vm._v(
                                                          "\n                        weekly\n                      "
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", md: "6" }
                                                },
                                                [
                                                  !_vm.ownAccount
                                                    ? _c("v-switch", {
                                                        attrs: {
                                                          inset: "",
                                                          label:
                                                            "Limit to certain devices"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newRecipientIsLimited,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.newRecipientIsLimited = $$v
                                                          },
                                                          expression:
                                                            "newRecipientIsLimited"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  !_vm.ownAccount
                                                    ? _c("v-select", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.newRecipientIsLimited,
                                                            expression:
                                                              "newRecipientIsLimited"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "all-the-width pr-2 pl-2",
                                                        attrs: {
                                                          disabled: !_vm.newRecipientIsLimited,
                                                          items:
                                                            _vm.accountDevices,
                                                          "prepend-icon":
                                                            "smartphone",
                                                          block: "",
                                                          solo: "",
                                                          multiple: "",
                                                          label:
                                                            "Limit to devices",
                                                          "item-text":
                                                            "deviceName",
                                                          "item-value":
                                                            "deviceId"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newRecipient
                                                              .identities,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newRecipient,
                                                              "identities",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newRecipient.identities"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm.featureFlags.immediateAlerts
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                        "offset-md": "6"
                                                      }
                                                    },
                                                    [
                                                      _c("v-switch", {
                                                        attrs: {
                                                          inset: "",
                                                          label:
                                                            "Send Immediate Alerts",
                                                          hint:
                                                            "Immediately alert when bad sites/apps visited.  Limited to one email every 15 minutes per device.",
                                                          "persistent-hint": true
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.alertImmediately,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.alertImmediately = $$v
                                                          },
                                                          expression:
                                                            "alertImmediately"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.alertImmediately &&
                                              _vm.featureFlags.phoneNumber
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                        "offset-md": "6"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "vue-phone-number-input",
                                                        {
                                                          attrs: {
                                                            "only-countries": [
                                                              "US",
                                                              "CA"
                                                            ],
                                                            error:
                                                              _vm.newRecipientPhoneNumberError ||
                                                              _vm.newRecipientPhoneNumberTypeError,
                                                            "error-color":
                                                              "#b71c1c",
                                                            "valid-color":
                                                              "#00796B",
                                                            color: "#FFC107"
                                                          },
                                                          on: {
                                                            update:
                                                              _vm.onPhoneNumberUpdate
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.newRecipient
                                                                .phoneNumber,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newRecipient,
                                                                "phoneNumber",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newRecipient.phoneNumber"
                                                          }
                                                        }
                                                      ),
                                                      _vm.newRecipientPhoneNumberTypeError
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "error--text text-right"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Mobile phone number required"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.ownAccount
                                                ? [
                                                    !_vm.newRecipientLimitDataAccess
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "pt-0 pb-0",
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mt-0",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  outlined: ""
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.newRecipient.canView =
                                                                      _vm.defaultCanView
                                                                  },
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.newRecipientLimitDataAccess = !_vm.newRecipientLimitDataAccess
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        Limit access to data\n                      "
                                                                )
                                                              ]
                                                            ),
                                                            _c("span")
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.newRecipientLimitDataAccess
                                                      ? [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "title"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Data Limits"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "  \n                        "
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "caption error--text",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.newRecipientLimitDataAccess = false
                                                                    },
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.newRecipient.canView = _vm.newRecipientLimitDataAccess
                                                                        ? _vm.defaultCanView
                                                                        : null
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          (remove limits)\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pt-0 pb-0",
                                                              attrs: {
                                                                cols: "12",
                                                                md: "6"
                                                              }
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "mt-0 mb-0",
                                                                attrs: {
                                                                  inset: "",
                                                                  label:
                                                                    "Screenshots",
                                                                  "persistent-hint": true,
                                                                  hint:
                                                                    "Toggle to enable/disable access to screenshots."
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.newRecipient.canView.screenshotMetaData =
                                                                      _vm.newRecipient.canView.screenshots
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .newRecipient
                                                                      .canView
                                                                      .screenshots,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .newRecipient
                                                                        .canView,
                                                                      "screenshots",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "newRecipient.canView.screenshots"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pt-0 pb-0",
                                                              attrs: {
                                                                cols: "12",
                                                                md: "6"
                                                              }
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "mt-0 mb-0",
                                                                attrs: {
                                                                  disabled: !_vm
                                                                    .newRecipient
                                                                    .canView
                                                                    .screenshots,
                                                                  inset: "",
                                                                  label:
                                                                    "Screenshot Meta Data",
                                                                  "persistent-hint": true,
                                                                  hint:
                                                                    "Toggle to enable/disable viewing Date & Time, App Name, and Website captured within screenshots."
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .newRecipient
                                                                      .canView
                                                                      .screenshotMetaData,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .newRecipient
                                                                        .canView,
                                                                      "screenshotMetaData",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "newRecipient.canView.screenshotMetaData"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pt-0 pb-0",
                                                              attrs: {
                                                                cols: "12",
                                                                md: "6"
                                                              }
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "mt-0 mb-0",
                                                                attrs: {
                                                                  inset: "",
                                                                  label:
                                                                    "Websites",
                                                                  "persistent-hint": true,
                                                                  hint:
                                                                    "Toggle to enable/disable access to list of websites visited."
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.newRecipient.canView.eventTimeline =
                                                                      _vm
                                                                        .newRecipient
                                                                        .canView
                                                                        .websites &&
                                                                      !_vm
                                                                        .newRecipient
                                                                        .canView
                                                                        .highRiskOnly
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .newRecipient
                                                                      .canView
                                                                      .websites,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .newRecipient
                                                                        .canView,
                                                                      "websites",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "newRecipient.canView.websites"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pt-0 pb-0",
                                                              attrs: {
                                                                cols: "12",
                                                                md: "6"
                                                              }
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "mt-0 mb-0",
                                                                attrs: {
                                                                  inset: "",
                                                                  disabled:
                                                                    _vm
                                                                      .newRecipient
                                                                      .canView
                                                                      .highRiskOnly,
                                                                  label:
                                                                    "Screen & App Time",
                                                                  "persistent-hint": true,
                                                                  hint:
                                                                    "Toggle to enable/disable access to Screen & App Time"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .newRecipient
                                                                      .canView
                                                                      .screenAppTime,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .newRecipient
                                                                        .canView,
                                                                      "screenAppTime",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "newRecipient.canView.screenAppTime"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pt-0 pb-0",
                                                              attrs: {
                                                                cols: "12",
                                                                md: "6"
                                                              }
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "mt-0 mb-0",
                                                                attrs: {
                                                                  inset: "",
                                                                  label:
                                                                    "Event Timeline",
                                                                  "persistent-hint": true,
                                                                  hint:
                                                                    "Toggle to enable/disable access to the Event Timeline",
                                                                  disabled:
                                                                    _vm
                                                                      .newRecipient
                                                                      .canView
                                                                      .highRiskOnly ||
                                                                    !_vm
                                                                      .newRecipient
                                                                      .canView
                                                                      .websites
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .newRecipient
                                                                      .canView
                                                                      .eventTimeline,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .newRecipient
                                                                        .canView,
                                                                      "eventTimeline",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "newRecipient.canView.eventTimeline"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pt-0 pb-0",
                                                              attrs: {
                                                                cols: "12",
                                                                md: "6"
                                                              }
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "mt-0 mb-0",
                                                                attrs: {
                                                                  inset: "",
                                                                  label:
                                                                    "High Risk Only",
                                                                  color:
                                                                    "error",
                                                                  "persistent-hint": true,
                                                                  hint:
                                                                    "Toggle to limit access only high risk screenshots/websites/events.  Please be aware data may be miscategorized and this could introduce loopholes in accountability."
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.newRecipient.canView.screenAppTime = _vm.newRecipient.canView.eventTimeline = !_vm
                                                                      .newRecipient
                                                                      .canView
                                                                      .highRiskOnly
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .newRecipient
                                                                      .canView
                                                                      .highRiskOnly,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .newRecipient
                                                                        .canView,
                                                                      "highRiskOnly",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "newRecipient.canView.highRiskOnly"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      : _vm._e()
                                                  ]
                                                : _vm._e(),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "newRecipient.textField",
                                                      rules: "required|email"
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "text-center",
                                                                    attrs: {
                                                                      cols: "12"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        staticStyle: {
                                                                          "min-width":
                                                                            "200px"
                                                                        },
                                                                        attrs: {
                                                                          name:
                                                                            "new-recipient-text-field",
                                                                          label:
                                                                            "Email address",
                                                                          required:
                                                                            "",
                                                                          filled:
                                                                            "",
                                                                          error: !!errors.length,
                                                                          "error-messages": errors
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .newRecipient
                                                                              .textField,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.newRecipient,
                                                                              "textField",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "newRecipient.textField"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "float-left",
                                                                        attrs: {
                                                                          light:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            _vm.addingNewRecipient = false
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Cancel"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "white--text float-right",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                          disabled:
                                                                            _vm.cantAdd ||
                                                                            errors.length >
                                                                              0 ||
                                                                            !_vm
                                                                              .newRecipient
                                                                              .textField ||
                                                                            _vm.newRecipientPhoneNumberError ||
                                                                            !_vm.someDataAccessAllow
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.addEmail
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Submit"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ])
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }