<template>
  <v-container>
    <loading v-if="loading"></loading>
    <v-card class="centered2" max-width="800px" v-else>
      <v-card-title class="primary">
        <span class="white--text headline">Reports</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-alert :value="$store.getters.isSubscribed === false" v-if="$store.getters.isSubscribed === false" class="centered mt-2" color="warning">
                <v-icon color="accent" class="mr-2">warning</v-icon>
                <span class="white--text">Emails will not be sent, because your account is not subscribed.</span>
                <v-icon color="accent" class="ml-2">warning</v-icon>
                <v-btn color="accent" @click="$router.push('account?subscribe=true')" block class="mt-2">Subscribe</v-btn>
              </v-alert>
            </v-col>
            <v-col cols="12">
              Report recipients will be emailed a daily or weekly link enabling them to view screenshots.  They will also be notified of tamper or uninstall attempts, deleted screenshots, and monitoring changes.  Weekly reports are sent on Sunday shortly after midnight UTC time.  Daily reports are sent at midnight UTC time.
            </v-col>
            <template v-for="emailObj in emails">
              <v-col cols="12" :key="`${emailObj.email}.${emailObj.frequency}`">
                <v-card outlined>
                  <v-card-title class="text-lowercase">
                    {{emailObj.email}}
                  </v-card-title>
                  <v-card-subtitle class="text-capitalize pb-1">
                    {{emailObj.frequency + ' reports'}}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6" lg="4" v-if="featureFlags.immediateAlerts"  class="pt-1 pb-1">
                        {{'Immediate Alerts: ' + (emailObj.alertImmediately ? 'Enabled' : 'Disabled')}}
                      </v-col>
                      <v-col cols="12" md="6" lg="4" v-if="featureFlags.phoneNumber"  class="pt-1 pb-1">
                        {{`Phone Number: ${emailObj.phoneNumber ? emailObj.phoneNumber : 'none'}`}}
                      </v-col>
                      <v-col cols="12" md="6" lg="4" class="pt-1 pb-1">
                        <span>Limited to:</span>
                        <template v-if="emailObj.identities && emailObj.identities.length > 0">
                        <span v-for="device in emailObj.identities" :key="device.id">
                          {{device.name + ', '}}
                        </span>
                        </template>
                        <span v-else class="text-capitalize"> all devices</span>
                      </v-col>
                      <v-col cols="12" v-if="emailObj.canView" class="pt-1 pb-1">
                        Access to: <br />
                        <div class="ml-2">
                          <label class="font-weight-bold">Screenshots: </label>
                          {{`${emailObj.canView.screenshots ? 'Yes' : 'No'}`}} <br />
                          <label class="font-weight-bold">Screenshot Meta Data: </label>
                          {{`${emailObj.canView.screenshotMetaData ? 'Yes' : 'No'}`}} <br />
                          <label class="font-weight-bold">Websites: </label>
                          {{`${emailObj.canView.websites ? 'Yes' : 'No'}`}} <br />
                          <label class="font-weight-bold">Screen & App Time: </label>
                          {{`${emailObj.canView.screenAppTime ? 'Yes' : 'No'}`}} <br />
                          <label class="font-weight-bold">Event Timeline: </label>
                          {{`${emailObj.canView.eventTimeline ? 'Yes' : 'No'}`}} <br />
                          <label class="font-weight-bold">High Risk Only Data:</label>
                          {{`${emailObj.canView.highRiskOnly ? 'Yes' : 'No'}`}}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn
                      :outlined="!$store.state.isMobile"
                      :icon="$store.state.isMobile"
                      color="error"
                      @click="deleteEmail(emailObj)"
                      :disabled="$store.getters.isAccountLocked">
                      <v-icon>delete</v-icon>
                      <span v-if="!$store.state.isMobile">Delete</span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </template>

            <v-col cols="12">
              <p style="font-style: italic; padding: 0 1em 0.5em 1em;" v-if="$store.getters.isAccountLocked">
                You cannot delete report recipients because your account is locked.  To unlock, go to the <router-link to="account">account</router-link> page.
              </p>
            </v-col>
            <v-col cols="12" v-if="!addingNewRecipient">
              <template v-if="$store.getters.isAccountLocked">
                <div>Your account is locked, so you cannot add anyone to receive reports.  If you wish to unlock your account you can do so from the <router-link to="account">accounts page</router-link>.</div>
              </template>
              <template v-else>
                <v-btn  color="primary" class="white--text mt-2" @click="addingNewRecipient = true"
                        :disabled="emails.length > 4"
                        style="display: block; margin-left: auto; margin-right: auto;">+ Add report recipient</v-btn>
                <div v-if="emails.length > 4">You can only send reports to 5 email addresses.</div>
              </template>
            </v-col>
            <v-col cols="12" v-else>
              <v-card outlined>
                <v-card-title>
                  Add new recipient
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <label class="mr-3">Send reports:</label>&nbsp;
                      <v-btn-toggle mandatory v-model="newRecipientFrequency" color="primary">
                        <v-btn>
                          daily
                        </v-btn>
                        <v-btn>
                          weekly
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-switch
                        v-if="!ownAccount"
                        inset
                        label="Limit to certain devices"
                        v-model="newRecipientIsLimited"
                      >
                      </v-switch>
                      <v-select
                        v-if="!ownAccount"
                        v-show="newRecipientIsLimited"
                        :disabled="!newRecipientIsLimited"
                        :items="accountDevices"
                        prepend-icon="smartphone"
                        block
                        solo
                        multiple
                        label="Limit to devices"
                        item-text="deviceName"
                        item-value="deviceId"
                        class="all-the-width pr-2 pl-2"
                        v-model="newRecipient.identities">
                      </v-select>
                    </v-col>

                    <v-col cols="12" md="6" offset-md="6" v-if="featureFlags.immediateAlerts">
                      <v-switch
                        inset
                        label="Send Immediate Alerts"
                        v-model="alertImmediately"
                        hint="Immediately alert when bad sites/apps visited.  Limited to one email every 15 minutes per device."
                        :persistent-hint="true"
                      >
                      </v-switch>
                    </v-col>
                    <v-col cols="12" md="6" offset-md="6" v-if="alertImmediately && featureFlags.phoneNumber">
                      <vue-phone-number-input
                        v-model="newRecipient.phoneNumber"
                        :only-countries="['US', 'CA']"
                        @update="onPhoneNumberUpdate"
                        :error="newRecipientPhoneNumberError || newRecipientPhoneNumberTypeError"
                        error-color="#b71c1c"
                        valid-color="#00796B"
                        color="#FFC107"
                      >
                      </vue-phone-number-input>
                      <div v-if="newRecipientPhoneNumberTypeError" class="error--text text-right">Mobile phone number required</div>
                    </v-col>

                    <template v-if="!ownAccount">
                      <v-col cols="12" class="pt-0 pb-0" v-if="!newRecipientLimitDataAccess">
                        <v-btn
                          class="mt-0"
                          color="secondary"
                          outlined
                          @change="newRecipient.canView = defaultCanView"
                          @click="newRecipientLimitDataAccess = !newRecipientLimitDataAccess"
                        >
                          Limit access to data
                        </v-btn>
                        <span></span>
                      </v-col>
                      <template v-if="newRecipientLimitDataAccess">
                        <v-col cols="12">
                          <span class="title">Data Limits</span>&nbsp;&nbsp;
                          <a class="caption error--text"
                             @click="newRecipientLimitDataAccess = false"
                             @change="newRecipient.canView = (newRecipientLimitDataAccess ? defaultCanView : null)">
                            (remove limits)
                          </a>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                          <v-switch
                            class="mt-0 mb-0"
                            inset
                            label="Screenshots"
                            @change="newRecipient.canView.screenshotMetaData = newRecipient.canView.screenshots"
                            :persistent-hint="true"
                            hint="Toggle to enable/disable access to screenshots."
                            v-model="newRecipient.canView.screenshots"
                          >
                          </v-switch>
                        </v-col>
                        <v-col cols="12" md="6"  class="pt-0 pb-0">
                          <v-switch
                            :disabled="!newRecipient.canView.screenshots"
                            class="mt-0 mb-0"
                            inset
                            label="Screenshot Meta Data"
                            :persistent-hint="true"
                            hint="Toggle to enable/disable viewing Date & Time, App Name, and Website captured within screenshots."
                            v-model="newRecipient.canView.screenshotMetaData"
                          >
                          </v-switch>
                        </v-col>
                        <v-col cols="12" md="6"  class="pt-0 pb-0">
                          <v-switch
                            class="mt-0 mb-0"
                            inset
                            @change="newRecipient.canView.eventTimeline = newRecipient.canView.websites && !newRecipient.canView.highRiskOnly"
                            label="Websites"
                            :persistent-hint="true"
                            hint="Toggle to enable/disable access to list of websites visited."
                            v-model="newRecipient.canView.websites"
                          >
                          </v-switch>
                        </v-col>
                        <v-col cols="12" md="6"  class="pt-0 pb-0">
                          <v-switch
                            class="mt-0 mb-0"
                            inset
                            :disabled="newRecipient.canView.highRiskOnly"
                            label="Screen & App Time"
                            :persistent-hint="true"
                            hint="Toggle to enable/disable access to Screen & App Time"
                            v-model="newRecipient.canView.screenAppTime"
                          >
                          </v-switch>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                          <v-switch
                            class="mt-0 mb-0"
                            inset
                            label="Event Timeline"
                            :persistent-hint="true"
                            hint="Toggle to enable/disable access to the Event Timeline"
                            v-model="newRecipient.canView.eventTimeline"
                            :disabled="newRecipient.canView.highRiskOnly || !newRecipient.canView.websites"
                          >
                          </v-switch>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                          <v-switch
                            class="mt-0 mb-0"
                            inset
                            label="High Risk Only"
                            color="error"
                            @change="newRecipient.canView.screenAppTime = newRecipient.canView.eventTimeline = !newRecipient.canView.highRiskOnly"
                            :persistent-hint="true"
                            hint="Toggle to limit access only high risk screenshots/websites/events.  Please be aware data may be miscategorized and this could introduce loopholes in accountability."
                            v-model="newRecipient.canView.highRiskOnly"
                          >
                          </v-switch>
                        </v-col>
                      </template>
                    </template>

                    <v-col cols="12">
                      <validation-provider name="newRecipient.textField" rules="required|email" v-slot="{ errors }">
                        <v-row>
                          <v-col cols="12" class="text-center">
                            <v-text-field
                              name="new-recipient-text-field"
                              label="Email address"
                              required
                              v-model="newRecipient.textField"
                              filled
                              :error="!!errors.length"
                              :error-messages="errors"
                              style="min-width: 200px;"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-btn light class="float-left" @click="addingNewRecipient = false">Cancel</v-btn>
                          </v-col>
                          <v-col cols="6">
                            <v-btn color="primary" class="white--text float-right" @click="addEmail" :disabled="cantAdd || errors.length > 0 || !newRecipient.textField || newRecipientPhoneNumberError || !someDataAccessAllow" >Submit</v-btn>
                          </v-col>
                        </v-row>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { ValidationProvider, extend } from 'vee-validate';
  // import VuePhoneNumberInput from 'vue-phone-number-input'

  import 'vue-phone-number-input/dist/vue-phone-number-input.css';

  import { required, email } from 'vee-validate/dist/rules';
  extend('required', {
    ...required,
    message: 'Email is required'
  });
  extend('email', {
    ...email,
    message: 'Must be a valid email address'
  })


  const arrayDefinedAndSizeGreaterThanZero = (arr) => {
    return arr && arr.length > 0
  }

  const defaultCanView = {
    highRiskOnly: false,
    screenshots: true,
    screenshotMetaData: true,
    screenAppTime: true,
    websites: true,
    eventTimeline: true
  }

  const allowedPhoneTypes = new Set(['MOBILE', 'FIXED_LINE_OR_MOBILE'])

  import Loading from "./Loading";
  import SegmentTracking from "../services/SegmentTracking";

  export default {
    name: 'emails',
    components: {
      Loading,
      ValidationProvider,
      VuePhoneNumberInput: () => import('vue-phone-number-input')
    },
    created () {

    },
    data () {
      return {
        defaultCanView: defaultCanView,
        featureFlags: {
          immediateAlerts: true,
          phoneNumber: false
        },
        emails: [],
        loading: true,
        addingNewRecipient: false,
        newRecipient: {
          textField: "",
          identities: [],
          phoneNumber: null,
          alertImmediately: false,
          newRecipientFrequency: 0,
          canView: defaultCanView
        },
        newRecipientLimitDataAccess: false,
        newRecipientPhoneNumberError: false,
        newRecipientPhoneNumberTypeError: false,
        newRecipientIsLimited: false,
        alertImmediately: false,
        newRecipientFrequency: 0,
        emailValidated: false
      }
    },
    mounted() {
      this.loadEmails()
    },
    computed: {
      ownAccount() {
        return this.$store.state.account && this.newRecipient.textField.toLowerCase() === this.$store.state.account.accountId.toLowerCase()
      },
      someDataAccessAllow() {
        let cv = this.newRecipient.canView
        if(cv.screenshots) {
          return true
        } else if(cv.websites) {
          return true
        } else if(cv.eventTimeline) {
          return true
        } else if(cv.screenAppTime) {
          return true
        }
        return false
      },
      newRecipientFrequencyAsString() {
        let frequency = this.newRecipientFrequency === 0 ? "daily" : "weekly"
        return frequency
      },
      cantAdd () {
        if(!this.emails || this.emails.length === 0) {
          return false
        }

        console.log(`${this.newRecipientFrequency} ${this.newRecipientFrequencyAsString}`)

        for(let emailObj of this.emails) {
          if(emailObj.email === this.newRecipient.textField) {
            if(emailObj.frequency === this.newRecipientFrequencyAsString || (!emailObj.frequency && this.newRecipientFrequencyAsString === 'daily')) {
              return true
            } else {

              //list of limits to identities must match...
              // theres a chance old ones have deleted identities still, this is an edge case we aren't fixing now.
              // Few people use this so far, so the effort doesn't seem worth it yet.

              let ei = arrayDefinedAndSizeGreaterThanZero(emailObj.identities)
              let nri = arrayDefinedAndSizeGreaterThanZero(this.newRecipient.identities)

              if(ei && nri) {
                if(emailObj.identities.length !== this.newRecipient.identities.length) {
                  return true
                } else {
                  let sortedEmailIdentities = emailObj.identities.sort()
                  let sortedNRI = this.newRecipient.identities.slice().sort()

                  for(let c = 0; c < sortedEmailIdentities.length; c++) {
                    if(sortedEmailIdentities[c] !== sortedNRI[c]) {
                      return true
                    }
                  }
                }
              } else if(!ei && nri) {
                return true
              } else if(ei && !nri) {
                return true
              }
            }
          }
        }

        return false
      },
      accountDevices () {
        return this.$store.getters.getDevices
      },
      emailsWithFilteredIdentities () {
        //when a device is deleted, we don't remove the identity from the list of devices to receive screenshots from... so this hides that client side so the name dissapears
        // and doesn't cause any confusion if they've added an identically named device back.

        let devices = this.$store.getters.getDevices

        let ret = JSON.parse(JSON.stringify(this.emails))
        for(let emailObj of ret) {
          if(emailObj.identities && emailObj.identities.length > 0) {
            let tempIdentities = []
            for(let limit of emailObj.identities) {
              for(let device of devices) {
                if(device.deviceId === limit.id) {
                  tempIdentities.push(limit)
                }
              }
            }
            emailObj.identities = tempIdentities
          }
        }

        return ret
      }
    },
    methods: {
      onPhoneNumberUpdate(payload) {
        this.newRecipientPhoneNumberError = !payload.isValid && !!payload.phoneNumber
        this.newRecipientPhoneNumberTypeError = !!payload.phoneNumber && !allowedPhoneTypes.has(payload.type)
      },
      buildEmailValidationRules() {
        var self = this;
        return [
          function(emailAddress) {

            var rules = [
              function(emailAddress) {
                // eslint-disable-next-line
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(emailAddress) ? true : 'Invalid email address';
              },
              function(emailAddress) {
                var ret = true;
                self.emails.forEach(function(emailObj) {
                  if(emailObj.email === emailAddress) {
                    ret = false;
                  }
                });
                return ret ? true : 'That email is already added';
              }
            ];

            for(var c = 0; c < rules.length; c++) {
              var returnedValue = rules[c](emailAddress);
              if(returnedValue !== true) {
                self.emailValidated = false;
                return returnedValue;
              }
            }

            self.emailValidated = true;
            return true;
          }
        ];
      },
      async addEmail() {
        let frequency = this.newRecipientFrequency === 0 ? "daily" : "weekly"

        this.loading = true;

        let identities = []
        if(this.newRecipientIsLimited) {
          for(let d of this.accountDevices) {
            if(this.newRecipient.identities.indexOf(d.deviceId) !== -1) {
              identities.push({
                id: d.deviceId,
                name: d.deviceName
              })
            }
          }
        }

        console.log(identities)

        try {


          if(!this.newRecipientLimitDataAccess || this.ownAccount) {
            delete this.newRecipient.canView
          }

          let result = await this.api.addEmailAwait(this.newRecipient.textField, frequency, identities, this.alertImmediately, this.newRecipient.phoneNumber, this.newRecipient.canView)

          if(result.data.status !== 'Success') {
            this.$swal({
              title: `There was an error adding ${this.newRecipient.textField}`,
              text: result.data.message
            })
          }

          console.log(result.data)

          SegmentTracking.track('AccountabilityPartnerAdded', {
            category: 'Onboarding'
          })

          this.newRecipient.textField = ""
          this.newRecipient.identities = []
          this.addingNewRecipient= false

        } catch (err) {
          console.log(err)
          this.$swal({
            title: 'There was an issue adding the recipient.',
            text: err.toString()
          })
        } finally {
          this.loadEmails()
        }
      },
      async loadEmails() {
        this.loading = true;

        try {
          let ret = await this.api.getEmails()
          this.emails = ret.data
        } catch(err) {
          // eslint-disable-next-line no-console
          console.log(err)
          alert('issue loading emails')
        } finally {
          this.loading = false
        }
      },
      async deleteEmail(emailObj) {
        this.loading = true;

        try {
          await this.api.removeEmail(emailObj.email, emailObj.frequency)
        } catch(err) {
          alert('issue removing recipient, please try again.  If this persists, please email support@truple.io.')
        } finally {
          this.loadEmails()
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>

  .data-limit {
    border: 1px solid gray;
    /*border-radius: 5px;*/

  }

  .break-word{
    word-break: break-all;
  }
</style>
